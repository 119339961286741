import { useCallback } from 'react';

import { setHeapUser } from '@almond/analytics';
import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

import { CDN_COOKIE_NAME } from '~/modules/documents/config';

export const useLogout = () => {
  const { logout } = useAuth0();

  return useCallback(() => {
    Cookies.remove(CDN_COOKIE_NAME);
    setHeapUser(null);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
};
