import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';

import { env } from '~env';

import { printJestWarnings, processResponse } from '../utils';
import { useGetHeaders } from './useGetHeaders';
import { useLogout } from './useLogout';

import type {
  AddPrefix,
  ApiRestEndpoint,
  HttpMethod,
  paths,
  RequestBody,
  RequestParams,
  StripPrefix,
  SuccessResponse,
} from '@almond/api-types';
import type { SWRConfiguration, SWRResponse } from 'swr';

export type AlmondApiQueryOptions<Path extends StripPrefix<keyof paths>, Method extends HttpMethod> =
  | SWRConfiguration<SuccessResponse<ApiRestEndpoint<AddPrefix<Path>, Method>>>
  | (RequestParams<ApiRestEndpoint<AddPrefix<Path>, Method>> extends never
      ? never
      : { query: RequestParams<ApiRestEndpoint<AddPrefix<Path>, Method>> })
  // If we want to run a query with a method besides GET
  | { method: Method; body?: RequestBody<ApiRestEndpoint<AddPrefix<Path>, Method>, 'application/json'> };

// Extract type so return type is simpler
type Response<Path extends StripPrefix<keyof paths>, Method extends HttpMethod> = SuccessResponse<
  ApiRestEndpoint<AddPrefix<Path>, Method>
>;

/**
 * Custom hook to call Almond APIs.
 * @param path API path to call
 * @param options SWR options
 * @returns SWR Return object
 */
export const useAlmondApiQuery = <Path extends StripPrefix<keyof paths>, Method extends HttpMethod = 'get'>(
  path: Path | null,
  // TODO figure out how to make this required for paths with query params
  options: AlmondApiQueryOptions<Path, Method> = {} as AlmondApiQueryOptions<Path, Method>
): SWRResponse<Response<Path, Method>> => {
  const { isLoading, isAuthenticated } = useAuth0();
  const getHeaders = useGetHeaders();
  const logout = useLogout();

  printJestWarnings(isLoading, isAuthenticated);

  let query: URLSearchParams | undefined;

  if ('query' in options && options.query) {
    query = new URLSearchParams();
    Object.entries(options.query).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(v => query!.append(key, v.toString()));
      } else if (value !== undefined && value !== null) {
        query!.append(key, value.toString());
      }
    });
  }

  let pathWithQuery: string | null = path;

  if (path && query) {
    pathWithQuery += `?${query}`;
  }

  return useSWR(
    isLoading || !isAuthenticated || pathWithQuery === null
      ? null
      : [pathWithQuery, 'method' in options ? options.method : 'get', 'body' in options ? options.body : null],
    async ([url, method, body]: [string, Method, any]) => {
      const fullUrl = `${env.API_BASE_URL}/api/v2${url}`;
      const headers = await getHeaders(body ? 'application/json' : undefined);
      const res = await fetch(fullUrl, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
      });

      if (res.status === 401) {
        return logout();
      }

      return processResponse(res);
    },
    { ...options, revalidateOnFocus: 'revalidateOnFocus' in options ? options.revalidateOnFocus : false }
  );
};
