import { useEffect } from 'react';

import { useToastNotification } from '@almond/ui';

import { useAlmondApiMutation, useAlmondApiQuery } from '~/modules/api';

import type { TodoSourceUserRoleEnum } from '@almond/api-types';

export const useShowTodoAutocompletion = (patientUuid: string | undefined) => {
  const { showToast } = useToastNotification();
  const {
    data,
    isLoading,
    mutate: invalidate,
  } = useAlmondApiQuery(patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/` : null, {
    query: { archived_or_completed: true },
  });

  const { trigger } = useAlmondApiMutation(
    'post',
    patientUuid ? `/patients/${patientUuid as '{patient_uuid}'}/todos/read/` : null
  );

  return useEffect(() => {
    const toCall = async () => {
      if (!patientUuid || isLoading) {
        return;
      }

      const autoCompleteSources: TodoSourceUserRoleEnum[] = ['internal', 'system'];

      const completedUnreadTodos = data?.todos.filter(
        todo =>
          todo.completedAt &&
          todo.completionSource &&
          autoCompleteSources.includes(todo.completionSource) &&
          (!todo.readAt || new Date(todo.readAt) < new Date(todo.completedAt))
      );

      if (!completedUnreadTodos?.length) {
        return;
      }

      const title =
        completedUnreadTodos.length === 1 ? `"${completedUnreadTodos[0].title}" was` : 'Multiple todo items were';

      showToast({
        type: 'info',
        text: `✔️ ${title} marked as completed and moved to Archive`,
        duration: 5000,
      });

      await trigger({
        todoUuids: completedUnreadTodos.map(todo => todo.uuid),
      });

      await invalidate();
    };

    toCall();
  }, [patientUuid, isLoading, data?.todos, showToast, trigger, invalidate]);
};
