/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { Button, Checkbox, SimpleSelectInput, Text, useBrowserTypeMap, useTheme } from '@almond/ui';

import { DragDropFile, Modal } from '~/modules/ui';

import { HEALTH_RECORD_TYPES, MAX_ALLOWED_ATTACHMENTS } from '../../config';
import { useAddAttachments } from '../../hooks';
import { useDraftAttachmentContext } from '../DraftAttachmentProvider';
import { SmallPreviewDraftAttachment } from '../SmallPreview';

import { themedStyles } from './styles';

import type { HealthRecordTypesEnum } from '@almond/api-types';
import type { ModalProps } from '~/modules/ui';

// TODO figure out where else these values are needed and move them to a shared location
const accept = {
  'image/*': ['.png', '.jpg', '.jpeg'],
  'application/pdf': ['.pdf'],
};

export type UploadResult = File[] | { file: File; healthRecordType: HealthRecordTypesEnum };

type UploadModalProps = Pick<ModalProps, 'isVisible' | 'onRequestClose'> & {
  onApply: () => void;
};

export const UploadModal = (props: UploadModalProps) => {
  const { isVisible, onRequestClose, onApply } = props;
  const [styles] = useTheme(themedStyles);
  const { attachments, setAttachments, deleteAttachment } = useDraftAttachmentContext();
  const addAttachments = useAddAttachments(setAttachments);

  // We need to figure out a solution to attaching a health record and a shared media in 1 message.
  // Care Guides and Providers have requested this feature.
  // This component is all that's needed to make this work. Much of the below logic will be removed
  // or rewritten once we make that change.

  const [uploadToHealthRecords, setUploadToHealthRecords] = useState(
    typeof attachments[0]?.isHealthRecord === 'boolean' ? attachments[0].isHealthRecord : true
  );
  const updateCheckbox = (value: boolean) => {
    setUploadToHealthRecords(value);
    setAttachments(currentFiles =>
      currentFiles
        // Delete extra files
        .filter((_, i) => (value ? i < 1 : i < MAX_ALLOWED_ATTACHMENTS))
        .map(c => ({ ...c, isHealthRecord: value }))
    );
  };

  useEffect(() => {
    if (attachments.length === 0) {
      return;
    }

    if (attachments.every(f => f.isHealthRecord)) {
      setUploadToHealthRecords(true);
    } else if (attachments.every(f => !f.isHealthRecord)) {
      setUploadToHealthRecords(false);
    } else {
      setUploadToHealthRecords(false);
      setAttachments(currentFiles => currentFiles.map(f => ({ ...f, isHealthRecord: false })));
    }
  }, [attachments, setAttachments]);

  useEffect(() => {
    if (!props.isVisible) {
      setUploadToHealthRecords(
        typeof attachments[0]?.isHealthRecord === 'boolean' ? attachments[0].isHealthRecord : true
      );
    }
  }, [attachments, props.isVisible]);

  const [healthRecordType, setHealthRecordType] = useState<HealthRecordTypesEnum | undefined>();
  const updateHealthRecordType = (value: HealthRecordTypesEnum) => {
    setAttachments(currentFiles => currentFiles.map(c => ({ ...c, healthRecordType: value })));
    setHealthRecordType(value);
  };

  const maxFiles = uploadToHealthRecords ? 1 : MAX_ALLOWED_ATTACHMENTS;
  const maxFilesLeft = maxFiles - attachments.length;

  const { isMobile } = useBrowserTypeMap();

  const onDrop = (newFilesArr: File[]) => {
    addAttachments(newFilesArr, uploadToHealthRecords, healthRecordType);
  };

  return (
    <Modal
      isVisible={isVisible}
      onRequestClose={onRequestClose}
      title="Attach a File"
      // eslint-disable-next-line max-len
      subtitle="Health Records only accepts single-file uploads. Uncheck the box for multi-file uploads to Shared Media."
      footer={
        <View style={styles.footer}>
          <Checkbox checked={uploadToHealthRecords} onChange={updateCheckbox}>
            <Text style={styles.checkboxLabel}>Upload attachment to Health Records</Text>
          </Checkbox>
          <Button
            testID="UploadModal-Apply"
            onPress={onApply}
            style={styles.applyButton}
            size="m"
            isDisabled={attachments.length === 0 || attachments.some(f => f.isHealthRecord && !f.healthRecordType)}
            fixedWidth={false}
          >
            Apply
          </Button>
        </View>
      }
      showClose={isMobile}
    >
      <View style={styles.container}>
        {maxFilesLeft > 0 && <DragDropFile onDrop={onDrop} maxFiles={maxFilesLeft} accept={accept} />}
        {attachments.length > 0 && (
          <View role="list" style={styles.fileList}>
            {Array.from(attachments)
              .slice(0, maxFiles)
              .map(item => (
                <SmallPreviewDraftAttachment
                  key={item.id}
                  item={item}
                  role="listitem"
                  onDelete={() => {
                    deleteAttachment(item);
                  }}
                />
              ))}
          </View>
        )}
        {uploadToHealthRecords && (
          <SimpleSelectInput
            testID="healthRecordTypeSelect"
            options={HEALTH_RECORD_TYPES}
            value={healthRecordType}
            onSelect={updateHealthRecordType}
            closeModalTrigger={isVisible}
          />
        )}
      </View>
    </Modal>
  );
};
