import './channel-list-overrides.css';

import React, { useState } from 'react';
import { View } from 'react-native';

import { ActivityIndicator, Searchbar, useBrowserType, useTheme } from '@almond/ui';
import { useLocalSearchParams } from 'expo-router';
import { useChatContext } from 'stream-chat-react';

import { BottomSheet } from '~/modules/ui';
import { useCurrentUser } from '~/modules/user';

import { useChannel, useChannelStatus, useConversationMenu } from '../../hooks';
import { ChannelListItems } from './ChannelListItems';
import { Filters } from './Filters';

import { themedStyles } from './styles';

import type { Filter } from '../../types';
import type { ConversationDetailsParams } from '~/types';

const AlmondChannelList = () => {
  const [styles] = useTheme(themedStyles);
  const searchParams = useLocalSearchParams<ConversationDetailsParams>();
  const [initialConversationId] = useState(searchParams.conversationId);
  const shouldSetActiveChannelFromURL = !!initialConversationId;
  const { isReady } = useChannel(searchParams.conversationId, shouldSetActiveChannelFromURL);
  const { client } = useChatContext();
  const { statusVisibleToUser } = useChannelStatus();
  const [search, setSearch] = useState('');
  const [customFilter, setCustomFilter] = useState<Filter | undefined>(undefined);
  const currentUser = useCurrentUser();
  const browserType = useBrowserType();
  const { openConversationMenu, isVisible, actions, onDismiss } = useConversationMenu();

  if (!isReady) return <ActivityIndicator />;
  if (!currentUser || !client.user) return null;

  const filter = (() => {
    if (customFilter) {
      return customFilter;
    }

    const filtersMap: Record<string, Filter> = {
      careguide: 'ongoing',
      // Make sure providers don't see filters
      provider: 'member-of',
      admin: 'all',
    };
    const { role } = client.user;

    return role ? filtersMap[role] : undefined;
  })();

  return (
    <View testID="ChannelList" style={styles.channelListContainer}>
      <ChannelListItems
        filter={filter}
        search={search}
        openConversationMenu={openConversationMenu}
        renderAbove={({ isLoading }) => (
          <>
            <Searchbar onChangeText={text => setSearch(text)} placeholder="Name or DOB" isLoading={isLoading} />
            {!search && statusVisibleToUser && filter && <Filters filter={filter} setFilter={setCustomFilter} />}
          </>
        )}
      />
      {browserType === 'mobile' && <BottomSheet isVisible={isVisible} actions={actions} onRequestClose={onDismiss} />}
    </View>
  );
};

export { AlmondChannelList as ChannelList };
