import React from 'react';
import { View } from 'react-native';

import { Text, useTheme } from '@almond/ui';
import { combineUnstableStyles } from '@almond/utils';
import { Link } from 'expo-router';

import { ArtemisHeaderOptions, useArtemisHeaderOptions } from './ArtemisHeaderOptions';
import { BackButton } from './BackButton';
import { unstable_styles as cssStyles } from './Header.module.css';

import { themedStyles } from './styles';

import type { StyleProp, ViewStyle } from 'react-native';

export { ArtemisHeaderOptions };

export type HeaderProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  leftAlignTitle?: boolean;
  title?: string;
  style?: StyleProp<ViewStyle>;
};

export const Header: React.FC<HeaderProps> = props => {
  const { onBack, title } = useArtemisHeaderOptions();
  const [styles] = useTheme(themedStyles);

  const currentPage = props.title ?? (title || 'almond');

  const showCentered = !props.leftAlignTitle || props.left || onBack;

  const titleEle = (
    <Text
      fontStyle="bold"
      size={currentPage === 'almond' ? 'xxl' : 'xl'}
      style={[styles.title, showCentered ? styles.centerText : null]}
    >
      {currentPage === 'almond' ? <Link href="/">{currentPage}</Link> : currentPage}
    </Text>
  );

  const left = showCentered ? props.left : titleEle;

  return (
    <View style={combineUnstableStyles(styles.header, cssStyles.header, props.style)} testID="ArtemisHeader">
      <View style={styles.left}>{onBack ? <BackButton onBack={onBack} /> : left}</View>
      <View style={styles.center}>{showCentered && titleEle}</View>
      <View style={styles.right}>{props.right}</View>
    </View>
  );
};
