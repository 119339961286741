import { getIntakeFormsToComplete } from '@almond/utils';

import { useAlmondApiQuery } from '~/modules/api';
import { useBookUrl, useCurrentUser } from '~/modules/user';

const membershipSteps = (productName?: string) => [
  {
    title: `Complete your ${productName ? `${productName} Intake Forms` : 'Health History'}`,
    description: 'Tell us about your health goals, it usually takes about ~10-15 mins.',
  },
  {
    title: 'Your provider will review',
    description: `Before your visit your provider will review your \
${productName ? 'intake forms' : 'health history'} so they are prepared to guide you in meeting your health goals.`,
  },
  {
    title: 'Come to your visit',
    description: `Help us keep things running smoothly and arrive 5-10 minutes early to \
ensure we can make use of your full visit time. Please cancel or reschedule at least 24 \
hours in advance to avoid a charge.`,
  },
];

const lookupKeys = [
  'basic_annual_membership',
  'baseline',
  'virtual_membership',
  'menopause',
  'pcos',
  'maternity',
  'health_coaching',
];

export const useNextSteps = () => {
  const currentUser = useCurrentUser();
  const { data: patient, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );

  const remainingIntakeForms = getIntakeFormsToComplete(patient?.products, patient?.intakeForms);

  const { data: products, isLoading: isProductsLoading } = useAlmondApiQuery(
    remainingIntakeForms.length ? `/products/` : null,
    {
      query: { lookup_keys: lookupKeys },
    }
  );

  const ctaLink = useBookUrl({}, '/get-started');

  if (!patient || isProductsLoading) {
    // TODO should this be a loading indicator?

    return null;
  }

  // If a patient doesn't have an elation chart, they must've not completed the
  // sign up flow in Demi. So the dashboard should only allow them to book an
  // appointment (which should create the Elation patient).
  if (isLoading || !patient.elationChart) {
    return null;
  }

  const isHistoryComplete =
    patient.historyData && patient.consent && patient.pharmacy && patient.profile.address && patient.referral;

  const productName = remainingIntakeForms.length
    ? products?.products.find(p => p.almondProductKey === remainingIntakeForms[0].product)?.name
    : undefined;

  const steps = membershipSteps(productName);
  const cta = (() => {
    if (!isHistoryComplete) {
      if (patient.historyData) {
        return 'Finish Health History';
      }

      return 'Start Health History';
    }

    if (remainingIntakeForms.length) {
      if (remainingIntakeForms[0].type === 'msq') {
        return `Next: Start Symptoms Survey`;
      }

      return `Next: Start Intake`;
    }

    return null;
  })();

  if (!cta) {
    return null;
  }

  return {
    steps,
    cta,
    ctaLink,
  };
};
