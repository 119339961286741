import { sizes, styleSheetFactory } from '@almond/ui';

export const themedStyles = styleSheetFactory(theme => ({
  stepContainer: {
    paddingHorizontal: sizes.SPACING_L,
    paddingVertical: sizes.SPACING_S,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: sizes.SPACING_L,
    flexShrink: 1,
  },
  stepNumber: {
    color: theme.accent,
    verticalAlign: 'top',
  },
  stepTextContainer: {
    marginTop: sizes.SPACING_XS,
    flex: 1,
    gap: sizes.SPACING_XS,
  },
  completeHistory: {
    alignSelf: 'flex-start',
    minWidth: 160,
    paddingHorizontal: sizes.SPACING_M,
    marginTop: sizes.SPACING_XS,
  },
}));
