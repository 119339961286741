import React from 'react';
import { View } from 'react-native';

import { Button, Text, useTheme } from '@almond/ui';

import { useNextSteps } from '../../hooks';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const NextSteps = () => {
  const [styles] = useTheme(themedStyles);
  const nextSteps = useNextSteps();

  if (!nextSteps) return null;

  const { steps, cta, ctaLink } = nextSteps;

  return (
    <SectionWithItems title="Next Steps">
      {steps.map((step, index) => (
        <View style={styles.stepContainer} key={step.title}>
          <View>
            <Text fontStyle="medium" size="xxxxl" style={styles.stepNumber}>
              {index + 1}
            </Text>
          </View>
          <View style={styles.stepTextContainer}>
            <Text fontStyle="bold" size="xl">
              {step.title}
            </Text>
            <Text fontStyle="book" size="l">
              {step.description}
            </Text>
            {index === 0 && (
              <Button
                size="xs"
                href={ctaLink}
                fixedWidth={false}
                style={styles.completeHistory}
                testID="NextStepsWidgetCta"
              >
                {cta}
              </Button>
            )}
          </View>
        </View>
      ))}
    </SectionWithItems>
  );
};
