import React from 'react';

import { Button, ReproductiveIcon, useTheme } from '@almond/ui';

import { useAlmondApiQuery } from '~/modules/api';
import { useBookUrl, useCurrentUser } from '~/modules/user';
import { Belly } from '~assets';

import { ItemWithAction } from '../ItemWithAction';
import { SectionWithItems } from '../SectionWithItems';

import { themedStyles } from './styles';

export const GetCare: React.FC = () => {
  const currentUser = useCurrentUser();
  const { data, isLoading } = useAlmondApiQuery(
    currentUser?.patientUuid ? `/patients/${currentUser?.patientUuid as '{patient_uuid}'}/` : null
  );
  const [styles] = useTheme(themedStyles);
  const generalBookUrl = useBookUrl({ category: 'general' });
  const maternityBookUrl = useBookUrl({ category: 'Group Pregnancy Care' });
  const hasActiveSubscription = ['active', 'trialing'].includes(data?.profile?.subscription?.status || '');
  const loadingText = (() => {
    if (isLoading) return '';

    return hasActiveSubscription ? 'Book' : 'Join';
  })();

  return (
    <SectionWithItems
      title={hasActiveSubscription ? 'Get Care' : 'Looking for more in-depth care?'}
      isLoading={isLoading}
    >
      <ItemWithAction
        icon={<ReproductiveIcon />}
        title="Gynecology Care"
        action={
          <Button
            size="s"
            fixedWidth={false}
            style={styles.button}
            href={generalBookUrl}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {loadingText}
          </Button>
        }
        separator
      />
      <ItemWithAction
        lastItem
        icon={<Belly />}
        title="Maternity Care"
        action={
          <Button
            size="s"
            fixedWidth={false}
            style={styles.button}
            href={maternityBookUrl}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {loadingText}
          </Button>
        }
      />
    </SectionWithItems>
  );
};
